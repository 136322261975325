<template>
	<div
		class="container under-line-primary pb-20 mb-30-pc mb-20-m"
	>
		<div class="justify-start gap-10 items-center">
			<button
				@click="toBack"
				class="hide-pc box"
			><v-icon>mdi mdi-chevron-left</v-icon></button>
			<div class="text-20 size-px-16-m font-weight-bold ">{{ program.name }}</div>
		</div>
	</div>
</template>

<script>
	export default{
		name: 'Title'
		,props: ['program', 'cart_cnt', 'is_cart_view']
		,methods: {
			toBack: function(){
				if(this.program.from){
					this.$router.push({ name: this.program.from})
				}else{
					this.$router.back()
				}
			}
		}
	}
</script>