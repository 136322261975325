<template>
	<div
		class="position-fixed-full z-index-layer"
	>
		<div class="bg-layer z-index-bg"></div>
		<div
			class="position-relative flex-column justify-center full-width full-height z-index-contents"
			style="margin: 0 auto"
			:style="'max-width:' + (max ? max : '480') + 'px'"
		>
			<slot name="body"></slot>
		</div>

	</div>
</template>

<script>
export default {
	name: 'PopupLayer'
	, props: ['max']
}
</script>

<style>
	.position-fixed-full {
		position: fixed; left: 0; top: 0; width: 100%; height: 100%;
	}
	.z-index-layer {
		z-index: 9999;
	}
	.z-index-contents {
		z-index: 9998;
	}
	.z-index-bg {
		z-index: 9997;
	}

	.bg-layer {
		position: absolute; left: 0; top: 0; width: 100%; height: 100%; opacity: 0.5; background-color: black;
	}
</style>