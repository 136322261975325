import axios from 'axios'
import {Base64} from "js-base64";

let domains = process.env.VUE_APP_DOMAIN
let dev = process.env.VUE_APP_DEV
let server = process.env.VUE_APP_SERVER
let location = window.location.href

let baseUrl = ''

domains = domains.split('|')
for(let i = 0; i < domains.length; i++) {
	if (location.indexOf(domains[i]) > -1) {
		baseUrl = server
		break
	} else {
		baseUrl = dev
	}
}

export async function Axios({ method, url, data, header, multipart, blob, version }){

	const instance = axios.create({
		baseURL: baseUrl
		,timeout: 30000
	})

	const getUrl = function(){
		return url
	}

	const getParams = function(){
		if(method == 'get'){
			if(!data){
				data = {

				}
			}
			return data
		}
	}

	const getData = function(){
		if(method != 'get'){
			const formData = new FormData();
			for(let key in data){

				formData.append(key, data[key])
			}
			if(multipart){
				return formData
			}else{
				return formData
			}
		}
	}

	const getHeader = function(){

		let default_header = {
		}

		let TOKEN = sessionStorage.getItem(process.env.VUE_APP_NAME + 'AT')

		if(TOKEN){
			default_header.P = TOKEN
			Base64.encode(TOKEN)
		}

		if(multipart){
			default_header['Content-Type'] = 'multipart/form-data'
		}

		if(blob){
			default_header['responseType'] = 'blob'
		}

		if(version){
			default_header['V'] = version
		}

		return header ? header : default_header
	}

	try{
		const result = await instance({
			method: method
			,url: getUrl()
			,params: getParams()
			,data: getData()
			,headers: getHeader()
		})

		if(result.status == 200){

			if(!result.data.success){
				if(result.data.data?.code == 'E001001'){

					// document.location.href = process.env.VUE_APP_PUBLIC_PATH + 'Error/403'
				}
			}
			return result.data
		}else{
			const data = result.data
			console.log(data.status)
			if(data.status == 40120){
				return {success: false, message: '인증이 만료되었습니다'}
			}else{
				return {success: false, message: '통신오류: ' + result.status}
			}
		}
	}catch(E){
		console.log('Axios result error')
		console.log(E)

		return {success: false, message: '통신오류: ' + E}
	}
}



